import * as React from "react";
import styled from "styled-components";
import { ThemeProvider, StyleReset, Text } from "atomize";

const theme = {
  colors: {
    brand100: "#F03F37",
    brand200: "#6C4B9A",
    brand300: "#FFBD21"
  },
  grid: {
    containerMaxWidth: {
      xs: "1156px",
      sm: "1156px",
      md: "1156px",
      lg: "1156px",
      xl: "1156px"
    },
    gutterWidth: "32px"
  }
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <StyleReset />
      <Container>
        <Text textSize="display1" tag="h1">
          What The Bluck
        </Text>
        <Text textSize="" tag="p" textWeight="500">
          Coming soon
        </Text>
      </Container>
    </ThemeProvider>
  );
};
